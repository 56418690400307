import { Routes, Route, BrowserRouter } from "react-router-dom"
import Home from "../pages/home";
import Header from "../components/common/header";
import Footer from "../components/common/footer";

function Routing() {
    return (
        <BrowserRouter >

            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
            </Routes>
            <Footer />
        </BrowserRouter >
    )
}

export default Routing;