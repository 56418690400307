import Footer_logoImg from "../../assets/images/logo.png"

const Footer = () => {


    return (
        <footer className="text-center text-lg-start bg-body-tertiary text-muted">

            <section className="p-4">
                <div className="container text-center text-md-start mt-5">
                    <div className="row mt-3">
                        <div className="col-md-2 col-lg-2 col-xl-2 mb-4">
                            <img height="100" src={Footer_logoImg} alt="Footer_logoImg" />
                        </div>

                        <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                            <h6 className="fw-bold mb-4">
                                Links
                            </h6>
                            <p>
                                <a href="#!" className="text-reset">Home</a>
                            </p>
                            <p>
                                <a href="#!" className="text-reset">Our Platform</a>
                            </p>
                            <p>
                                <a href="#!" className="text-reset">Pipeline</a>
                            </p>
                            <p>
                                <a href="#!" className="text-reset">News &  Updates</a>
                            </p>
                        </div>

                        <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                            <h6 className="fw-bold mb-4">
                                Support
                            </h6>
                            <p>
                                <a href="#!" className="text-reset">FAQ</a>
                            </p>
                            <p>
                                <a href="#!" className="text-reset">Contact</a>
                            </p>
                        </div>

                        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                            <h6 className="fw-bold mb-4">Contact Us</h6>
                            <p><i className="fas fa-home me-3"></i> J-Hub Incubator, New Admissions Block</p>
                            <p><i className="fas fa-home me-3"></i> JNTU Hyderabad</p>
                            <p><i className="fas fa-home me-3"></i> Telangana - 500085, India</p>
                            <p>
                                <i className="fas fa-envelope me-3"></i>
                                connect@centella.co.in
                            </p>

                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className=" text-end p-4 " style={{ backgroundColor: "white" }}>
                    <span>
                        <a href="#" className="text-reset">Terms & Conditions</a>
                    </span>
                    <span>
                        &nbsp;|&nbsp;
                    </span>
                    <span>
                        <a href="#!" className="text-reset">Privacy Policy</a>
                    </span>
                </div>
            </section>

        </footer >
    )
}

export default Footer;