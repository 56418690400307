

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
// import AdbIcon from '@mui/icons-material/Adb';
import * as CONSTANTS from "../../utils/constants"
import Button from '@mui/material/Button';
import LogoImg from '../../assets/images/logo.png';

function Header() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <header style={{ backgroundColor: '#F7F7F7' }}>
            <nav class="navbar navbar-expand-lg navbar-light bg-light">
                <div className="d-flex container">
                    <div >
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <img src={LogoImg} height="80" alt="logo" />
                    </div>

                    <div class="container-sm justify-content-end collapse navbar-collapse" id="navbarTogglerDemo03">
                        <ul class="navbar-nav mr-auto mt-2 mt-lg-0 w-75 justify-content-evenly">
                            <li class="nav-item">
                                <a class="nav-link active" href="#">Home</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">Our Platform</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">Pipeline</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">News & Updates</a>
                            </li>
                        </ul>
                    </div>
                    <div class="">
                        <button style={{
                            borderRadius: 35,
                            backgroundColor: "#3C3C3C",
                            padding: "6px 20px",
                            fontSize: "12px",
                            color: 'white',
                            width: 'max-content'
                        }} class="btn my-2 my-sm-0" type="button">Contact Us</button>
                    </div>
                </div>
            </nav>
        </header>
    );
}
export default Header;