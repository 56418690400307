import react, { useEffect } from 'react';
import bg1Img from "../assets/images/bg1.jpg";
import request_money from "../assets/images/request_money_512px.png";
import time_img from "../assets/images/time_512px.png";
import improvement_img from "../assets/images/improvement_512px.png";
import muscle_img from "../assets/images/muscle_512px.png";
import adjust_img from "../assets/images/adjust_480px.png";
import growth_and_flag_img from "../assets/images/growth_and_flag_512px.png";
import sync_img from "../assets/images/sync_500px.png";
import teams_breakout_img from "../assets/images/teams_breakout_500px.png";
import Asset_img from "../assets/images/web/2x/Asset 1@2x.png";
import ADMET_img from "../assets/images/web/ADMET.jpg";
import dlabs_img from "../assets/images/footer_logo.jpg";
import cover_vid from "../assets/vid.MP4";
import image_3 from "../assets/images/image_3.JPG";
import image_11 from "../assets/images/image11.jpg";
import jhub_img from "../assets/images/jhub.png";
import contactus_img from "../assets/images/contactus.jpeg"
import isb_img from "../assets/images/ISB_transparent_logo.png"
import umg_img from "../assets/images/umg.png"

const Home = () => {


    return (
        <div className="home">
            <div>
                <div className="container-sm bg-overlay">
                    <div className="container bg-img-text" >
                        <h2> Centella AI Therapeutics </h2>
                        <h3 className="mb-4-1">Accelerating Drug Discovery Through Human And Machine Intelligence</h3>
                        <p> Centella presents a revolutionary solution to challenges of Drug DIscovery by harnessing
                            state-of-the-art Chemical Language Models (CLMs) and Generative AI algorithms. Our
                            approach encompasses De Novo Drug Design (DNDD), ADME & Toxicity Prediction,
                            Retrosynthesis, Virtual Screening, and Binding Affinity prediction. Our easy to use and
                            intelligent Platform empowers drug hunters to leverage these advanced tools without coding
                            expertise.</p>

                        <div className='mt-5'>
                            <button type='button' className='btn btn-light connect-btn'>Connect Now</button>
                        </div>
                    </div>
                </div>
                <div className='vid-main'>
                    <video muted autoPlay loop>
                        <source type="video/webm" src={cover_vid} />
                    </video>
                </div>
            </div>

            <div className="bg-container-2 " >
                <div className="container">
                    <div className="row bg-container-row container-sm">
                        <div className="col-sm">
                            <p className='m-0'>Why</p>
                            <h2 className='fw-bold'>Centella</h2>
                            <p>“Centella AI’s proprietary platform has the potential to revolutionize drug discovery
                                outcomes by significantly reducing the Design Make Test Analyse (DMTA) cycle time
                                by 25% and cutting costs by 60%. Our mission is to streamline drug discovery,
                                making it safer, faster, and more cost-effective, ultimately bringing life-saving
                                treatments to the patient community.
                                "</p>
                        </div>
                        <div className="col-lg">
                        </div>
                    </div>
                    <div className="row justify-content-md-between card-wrapper-main">
                        <div className="col-lg-2 col-md-3 card-wrapper">
                            <div className="icon">
                                <img className='height_icon' src={request_money} />
                            </div>
                            <div className="card card-shadow  height-150_767">
                                <div className="card-body">
                                    <label className="text">60%</label>
                                    <p>Drug R&D Cost</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 card-wrapper">
                            <div className="icon">
                                <img className='height_icon' src={time_img} />
                            </div>
                            <div className="card card-shadow  height-150_767">
                                <div className="card-body">
                                    <label className="text">25%</label>
                                    <p>Drug R&D Time</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 card-wrapper">
                            <div className="icon">
                                <img className='height_icon' src={improvement_img} />
                            </div>
                            <div className="card card-shadow  height-150_767">
                                <div className="card-body">
                                    <label></label>
                                    <p>Improved Safety & efficiency. Reduced toxicity</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-3">
                <div className="container">
                    <div className="d-flex">
                        <div className="text-center head-text">
                            <h2 className='fw-bold'>CENTELLA Benefits</h2>
                            <p>Centella AI Therapeutics employs an advanced AI-driven platform for drug discovery.
                                Our Scientific Multimodal Large Language Model (LLM) integrates diverse data,
                                including scientific text, proteins, molecules, and genomic information. Fine-tuned
                                modules ensure optimal performance in virtual screening, drug design, and ADMET
                                prediction. This approach, emphasizing data diversity, results in a robust foundation,
                                providing precise insights across drug discovery workflows.</p>
                        </div>
                    </div>
                    <div className="row justify-content-md-between mb-4 mx-auto">
                        <div className="col-lg-3 col-sm-4 col-xs-6 col-md-3 ">
                            <div className="icon-2">
                                <img height="35" src={teams_breakout_img} />
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <label className="fw-bolder" >Scalable Architecture</label>
                                    <p>Improved Safety & efficiency. Reduced toxicity</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-4 col-xs-6 col-md-3 ">
                            <div className="icon-2">
                                <img height="35" src={sync_img} />
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <label className="fw-bolder" >Seamless Integration</label>
                                    <p>Improved Safety & efficiency. Reduced toxicity</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-4 col-xs-6 col-md-3 ">
                            <div className="icon-2">
                                <img height="35" src={muscle_img} />
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <label className="fw-bolder" >Robust Security and Compliance</label>
                                    <p>Improved Safety & efficiency. Reduced toxicity</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-4 col-xs-6 col-md-3 ">
                            <div className="icon-2">
                                <img height="35" src={growth_and_flag_img} />
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <label className="fw-bolder" >High Performance</label>
                                    <p>Improved Safety & efficiency. Reduced toxicity</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-4 col-xs-6 col-md-3 ">
                            <div className="icon-2">
                                <img height="35" src={adjust_img} />
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <label className="fw-bolder" >Customizable Solutions</label>
                                    <p>Improved Safety & efficiency. Reduced toxicity</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div className="container-5">
                <div className="container container-5-main">
                    <div className="d-flex ">
                        <div className="">
                            <p className='m-0'>CENTELLA</p>
                            <h3>Platform</h3>
                        </div>
                    </div>
                    <div className="row justify-content-md-between mb-4">
                        <div className="col-sm-3 ">
                            <div className="card card-5-main">
                                <div className="card-body card-5">
                                    <div className="icon-3">
                                        <img className='img-3' src={Asset_img} />
                                    </div>
                                    <div className="text-3">
                                        <label className="text-3-main" >Generative AI Chem</label>
                                        <label className="d-flex"><span className='li'></span>Built on cutting edge Active Learning ML algorithm.</label>
                                        <label className="d-flex"><span className='li'></span>Hybrid Molecular Representation.</label>
                                        <label className="d-flex"><span className='li'></span>Reinforcement Learning for Compound Generation.</label>
                                        <label className="d-flex"><span className='li'></span>Multi-Objective Optimization.</label>
                                        <label className="d-flex"><span className='li'></span>Integrated Predictive ADMET Modelling.</label>
                                        <label className="d-flex"><span className='li'></span>Spatial Awareness.</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 ">
                            <div className="card card-5-main">
                                <div className="card-body card-5">
                                    <div className="icon-3">
                                        <img className='img-3' src={ADMET_img} />
                                    </div>
                                    <div className="text-3">
                                        <label className="text-3-main" >ADMET</label>
                                        <label className="d-flex"><span className='li'></span>Smiles featurization is independent of descriptors (Our USP).</label>
                                        <label className="d-flex"><span className='li'></span>2D & 3D Featurization of the input molecules.</label>
                                        <label className="d-flex"><span className='li'></span>Selection of optimal descriptors.</label>
                                        <label className="d-flex"><span className='li'></span>State of The Art (SOTA) in 85% Properties.</label>
                                        <label className="d-flex"><span className='li'></span>Graph representation of the molecules for better structure info retention.</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 ">
                            <div className="card card-5-main">
                                <div className="card-body card-5">
                                    <div className="icon-3">
                                        <img className='img-3' src={bg1Img} />
                                    </div>
                                    <div className="text-3">
                                        <label className="text-3-main" >Retrosynthesis</label>
                                        <label className="d-flex"><span className='li'></span>Predictive ML algorithms to forecast pathways from synthesis data, optimizing reaction mechanisms.</label>
                                        <label className="d-flex"><span className='li'></span>Dissects complex molecules into simpler precursors with a focus on efficiency and sustainability.</label>
                                        <label className="d-flex"><span className='li'></span>Applies graph theory and ML to identify efficient synthetic routes.</label>
                                        <label className="d-flex"><span className='li'></span>Utilizes MCDA for pathway selection, prioritizing synthetic accessibility.</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 ">
                            <div className="card card-5-main">
                                <div className="card-body card-5">
                                    <div className="icon-3">
                                        <img className='img-3' src={image_11} />
                                    </div>
                                    <div className="text-3">
                                        <label className="text-3-main" >Virtual Screening</label>
                                        {/* <label className="d-flex"><span className='li'></span>Integrating deep neural networks with cheminformatics for ground breaking molecular interaction forecasts.</label> */}
                                        <label className="d-flex"><span className='li'></span>Systematically screens vast chemical libraries to identify potential drug candidates </label>
                                        <label className="d-flex"><span className='li'></span>Advanced analytics dissect PK/PD and ADMET profiles with unparalleled precision.</label>
                                        <label className="d-flex"><span className='li'></span>AI strategies elevate compound selection, spotlighting efficacy and safety.</label>
                                        <label className="d-flex"><span className='li'></span>Adaptive algorithms boost selection speed, slashing computational load.</label>
                                        {/* <label className="d-flex"><span className='li'></span>Continuously refines models, integrating data for next-gen accuracy.</label> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div className="container-3 wrapper-6">
                <div className="container">
                    <div className="d-flex">
                        <div className="text-center head-text">
                            <h1>Welcome to our Collaborative ecosystem</h1>
                            <p> We are proud to be associated with global partners. Explore our network of collaborators and witness the transformative impact strategic alliances in shaping the future. </p>
                        </div>
                    </div>
                    <div className="row justify-content-md-between logo-6">
                        <div className="col-sm-6 col-md-3 logo-child">
                            <div className="card">
                                <div className="card-body logo-img-body">
                                    <img className='logo-img' src={jhub_img} />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 logo-child">
                            <div className="card">
                                <div className="card-body logo-img-body">
                                    <img className='logo-img' src={dlabs_img} />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 logo-child">
                            <div className="card">
                                <div className="card-body logo-img-body">
                                    <img style={{
                                        // height: "90px",
                                        // width: "200px",
                                        // margin: "auto"
                                    }} className='logo-img' src={image_3} />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 logo-child">
                            <div className="card">
                                <div className="card-body logo-img-body">
                                    <img className='logo-img' src={isb_img} />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 logo-child">
                            <div className="card">
                                <div className="card-body logo-img-body">
                                    <img className='logo-img' src={umg_img} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div className='container-4'>
                <div className='container w-80_767'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <h3>Join with us to Innovate! Collaborate! Discover!</h3>
                        </div>
                        {/* <div className='col-md-4'>
                            <input className='input' placeholder='Name' />
                            <input className='input' placeholder='Email' />
                            <input className='input'  placeholder='Mobile Number' />
                            <textarea className='input'  placeholder='message'/>
                        </div> */}
                        <div className='col-md-4'>
                            <form>
                                <div className="form-group mb-4">
                                    <input  className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Name" />
                                </div>
                                <div className="form-group mb-4">
                                    <input  className="form-control" id="exampleInputPassword1" placeholder="Contact" />
                                </div>
                                <div className="form-group mb-4">
                                    <input  className="form-control" id="exampleInputPassword1" placeholder="Email" />
                                </div>
                                <div className="form-group mb-4">
                                    <textarea  className="form-control" id="exampleInputPassword1" placeholder="Message" />
                                </div>
                                <div className="text-end ">
                                    <button type="submit" className="btn w-50">Send</button>
                                </div>
                            </form>
                        </div>
                        <div className='col-md-4'>
                            <img src={contactus_img} />
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default Home;